import { generateObjectId } from '@anm/analytic/playerAnalytic';
import isStreamVideo from '@anm/helpers/is/isStreamVideo';
import { useEffect, useState, RefObject, useRef } from 'react';

type HookProps = {
  autoplay?: boolean;
  hlsVideo?: string;
  canRetry?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
  canDestroy?: boolean;
};

type UseCheckHlsVideoProps = {
  canLoad: boolean;
  canRetry: boolean;
  hlsVideo: string;
};

const DELAY = 3000;

const useCheckHLSVideo = ({ hlsVideo, canLoad, canRetry }: UseCheckHlsVideoProps) => {
  const timer = useRef<NodeJS.Timeout>();
  const [hlsUrl, setHlsUrl] = useState('');
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (!canLoad) return;

    const checkHLS = async () => {
      try {
        setIsPending(true);
        const hlsUrl = `${hlsVideo}?${generateObjectId()}`;
        const hlsResponse = await (await fetch(hlsUrl)).text();
        const canInitHLS = hlsResponse.startsWith('#EXTM3U');

        if (canInitHLS) {
          setHlsUrl(hlsUrl);
          setIsPending(false);
        }

        if (!canInitHLS && canRetry) {
          timer.current = global.setTimeout(checkHLS, DELAY);
          return;
        }
      } catch (e) {
        console.error(e);
      }
    };

    checkHLS();

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [hlsVideo, canLoad]);

  return [hlsUrl, isPending] as const;
};

const useHLSVideo = ({ videoRef, hlsVideo = '', canDestroy = true, canRetry = false, autoplay = false }: HookProps) => {
  const canLoad = !!(hlsVideo && isStreamVideo(hlsVideo));
  const [hlsUrl, isPending] = useCheckHLSVideo({ hlsVideo, canRetry, canLoad });
  const [isInited, setIsInited] = useState(false);

  useEffect(() => {
    const video = videoRef?.current;
    if (!video || !hlsVideo) return;

    video.onplay = () => window.hls?.loadSource(hlsVideo);
  }, [videoRef?.current, hlsVideo]);

  useEffect(() => {
    (async () => {
      const video = videoRef?.current;
      const canInit = !!(video && canLoad && !isPending && !isInited);

      if (!canInit || !video) {
        return;
      }

      const HLS = (await import(/* webpackChunkName: "hls" */ 'hls.js')).default;

      if (!HLS.isSupported()) {
        video.src = hlsUrl;
        return;
      }

      const hls = new HLS();
      autoplay && hls.loadSource(hlsVideo);
      hls.attachMedia(video);
      hls.on(HLS.Events.MEDIA_ATTACHED, () => (window.hls = hls));
      setIsInited(true);
    })();
  }, [canLoad, videoRef?.current, hlsUrl, isPending, isInited, autoplay]);

  useEffect(() => {
    const canDestroyPlayer = canDestroy && window.hls;

    if (canDestroyPlayer) {
      return () => {
        window.hls.stopLoad();
        window.hls = null;
      };
    }
  }, [canDestroy, window.hls]);
};

export default useHLSVideo;
